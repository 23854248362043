.offer-gallery-col-1 {
  grid-column: 1 / 7;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include media-min-xs {
    grid-column: 1 / 8;
  }

  @include media-min-md {
    grid-column: 2 / 9;
  }

  @include media-min-xl {
    grid-column: 3 / 9;
  }

  @include media-min-ul {
    grid-column: 4 / 9;
  }
}

.offer-gallery-col-2 {
  grid-column: 7 / 17;
  grid-row: 1 / 2;
  @include media-min-xs {
    grid-column: 8 / 19;
  }

  @include media-min-md {
    grid-column: 9 / 18;
  }

  @include media-min-xl {
    grid-column: 9 / 17;
  }

  @include media-min-ul {
    grid-column: 9 / 16;
  }
}

.offer-image {
  display: flex;
  background: no-repeat center;
  background-size: cover;
  width: 100%;
  &:hover:after {
    content: ""; // ::before and ::after both require content
    width: 100%;
    height: 100%;
    background-color: $amati-black;
    opacity: .1;
  }
  @include m(small) {
    width: 100%;
    height: 124px;
    @include media-min-md {
      width: 50%;
      height: 154px;
    }
  }

  @include m(medium) {
    height: 124px;
    margin-bottom: $xs-gutter-width;
    @include media-min-md {
      height: 318px;
      margin-bottom: $md-gutter-width;
    }

    @include media-min-ul {
      margin-bottom: $ul-gutter-width;
    }
  }

  @include m(large) {
    height: 256px;
    @include media-min-md {
      height: 570px;
    }
  }
}
