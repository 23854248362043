.accordion {
  grid-column: 2 / 16;
  @include media-min-xs {
    grid-column: 3 / 17;
  }

  @include media-min-xl {
    grid-column: 5 / 15;
  }

  @include e(item) {
    margin-bottom: 56px;
    &.active {
      > .accordion-header {
        color: $amati-green;
        &:before {
          transform: rotate(0deg);
          background-image: url('../design/icon_arrow_green.svg');
        }
      }
    }
  }
}

.accordion-header {
  display: flex;
  cursor: pointer;
  padding-left: 36px; //Margin (16px) + Icon width (20px)
  &:before {
    position: absolute;
    margin-top: 4px; // Center icon vertically
    margin-left: -36px; //Margin (16px) + Icon width (20px)
    height: 20px;
    width: 20px;
    content: "";
    transition: transform 0.3s;
    transform: rotate(-90deg);
    background: no-repeat center/contain url('../design/icon_arrow.svg');
    @include media-min-lg {
      margin-left: -68px; //Margin (16px) + Icon width (20px)
      height: 28px;
      width: 28px;
    }
  }

  &:hover {
    color: $amati-green;
    &:before {
      background-image: url('../design/icon_arrow_green.svg');
    }
  }

  @include media-min-lg {
    padding-left: 68px; //Margin (16px) + Icon width (20px)
  }
}

.js-accordion-body {
  display: none;
}

.accordion-content {
  display: grid;
  align-items: flex-start;
  @extend .container;
  padding: 56px 0 0 0;
  &:first-child {
    @include media-min-md {
      padding-bottom: 48px;
    }
  }
  &:last-child {
    padding-bottom: 32px;
  }

  @include media-min-md {
    padding: 40px 0 0 0;
  }

  @include m(labels) {
    display: none;
    @include media-min-md {
      display: grid;
    }
  }

  @include e(col-text) {
    grid-column: 2 / 16;
    @include media-min-xs {
      grid-column: 3 / 17;
    }

    @include media-min-xl {
      grid-column: 5 / 14;
    }
  }

  @include e(col-1) {
    grid-column: 2 / 16;
    padding-bottom: 16px;
    @include media-min-xs {
      grid-column: 3 / 17;
    }

    @include media-min-md {
      grid-column: 3 / 10;
      padding: 0;
    }

    @include media-min-xl {
      grid-column: 5 / 10;
    }

    @include e(description) {
      @include media-min-sm {
        width: 80%;
      }

      @include media-min-md {
        width: 100%;
      }

      @include media-min-lg {
        width: 80%;
      }
    }
  }

  @include e(col-2) {
    grid-column: 6 / 16;
    @include media-min-xs {
      grid-column: 7 / 17;
    }

    padding-bottom: 8px;
    @include media-min-md {
      grid-column: 11 / 14;
      padding: 0;
    }

    @include media-min-xl {
      grid-column: 11 / 13;
    }
  }

  @include e(col-3) {
    grid-column: 6 / 16;
    @include media-min-xs {
      grid-column: 7 / 17;
    }

    @include media-min-md {
      grid-column: 14 / 17;
    }

    @include media-min-xl {
      grid-column: 13 / 15;
    }
  }

  @include e(mobile-label) {
    grid-column: 2 / 6;
    @include media-min-xs {
      grid-column: 3 / 7;
    }

    @include media-min-md {
      display: none;
    }
  }
}
