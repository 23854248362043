.home-cta {
  grid-column: 1 / 16;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: $zi-middle;
  padding: 64px 0;
  background-color: $amati-light-grey;
  > h1 {
    padding: 0 52px 0 52px;
  }

  @include media-min-xs {
    grid-column: 1 / 17;
  }

  @include media-min-sm {
    grid-column: 1 / 15;
    padding: 64px 0;
    > h1 {
      padding: 0 104px 0 104px;
    }
  }

  @include media-min-md {
    grid-column: 2 / 12;
    padding: 64px 0;
    > h1 {
      padding: 0 72px 0 72px;
    }
  }

  @include media-min-lg {
    padding: 72px 0;
    > h1 {
      padding: 0 80px 0 80px;
    }
  }

  @include media-min-xl {
    grid-column: 3 / 12;
    padding: 88px 0;
    > h1 {
      padding: 0 120px 0 120px;
    }
  }

  @include media-min-ul {
    padding: 96px 0;
    > h1 {
      padding: 0 160px 0 160px;
    }
  }
}

.home-image {
  grid-column: 2 / 17;
  grid-row: 1 / 2;
  background: url('../design/img_home.png') left center / cover;
  height: 470px;
  > img {
    display: none;
  }

  @include media-min-xs {
    grid-column: 3 / 19;
  }

  @include media-min-sm {
    grid-column: 5 / 19;
    height: 416px;
  }

  @include media-min-md {
    grid-column: 7 / 18;
    justify-self: flex-end;
    height: auto;
    > img {
      display: flex;
      width: 100%;
    }
  }

  @include media-min-lg {
    grid-column: 8 / 18;
  }

  @include media-min-xl {
    grid-column: 8 / 17;
  }
}

.news {
  grid-column: 2 / 16;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media-min-xs {
    grid-column-end: 18;
  }

  @include media-min-sm {
    grid-column: 3 / 17;
  }

  @include media-min-lg {
    grid-column: 2 / 18;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @include m(less-than-three) {
      justify-content: space-evenly;
    }
  }

  @include media-min-xl {
    grid-column: 3 / 17;
  }

  @include e(item) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 88px;
    &:last-child {
      margin-bottom: 0;
    }

    @include media-min-md {
      max-width: 312px;
    }

    @include media-min-lg {
      width: calc(4 * #{$xl-column-width} + 3 * #{$xl-gutter-width});
      max-width: none;
      margin-bottom: 0;
    }

    @include media-min-ul {
      width: calc(4 * #{$ul-column-width} + 3 * #{$ul-gutter-width});
    }

    @include e(image) {
      display: flex;
      width: 100%;
      height: 256px;
      background: no-repeat center;
      background-size: cover;
      margin-bottom: 56px;
      @include media-min-lg {
        margin-bottom: 56px;
      }
    }
  }
}

.social-media {
  grid-column: 2 / 16;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-min-xs {
    grid-column: 3 / 17;
  }

  @include media-min-lg {
    grid-column: 2 / 18;
    flex-direction: row;
  }

  @include media-min-xl {
    grid-column: 3 / 17;
  }

  @include e(link) {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    &:last-child {
      margin-right: 0;
    }
    > img {
      height: 32px;
      width: 32px;
      margin-right: 16px;
      @include media-min-xs {
        height: 48px;
        width: 48px;
        margin-right: 24px;
      }
    }

    @include media-min-lg {
      margin-right: 64px;
      margin-bottom: 0;
    }
    @include media-min-ul {
      margin-right: 96px;
    }
  }
}
