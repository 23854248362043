/*
 * Typography styles
 */

// Paragraph

p {
  font-family: 'Muli', sans-serif;
  font-size: $fs-p;
  line-height: $lh-p;
  font-weight: $fw-regular;
  text-align: center;
  margin: 0 0 12px 0;
  &:last-child {
    margin: 0;
  }
  @include media-min-lg {
    font-size: $fs-p-lg;
    line-height: $lh-p-lg;
    margin: 0 0 16px;

  }
}

.typo-paragraph-small, .typo-paragraph-small > p {
  font-family: 'Muli', sans-serif;
  font-size: $fs-p-small;
  line-height: $lh-p-small;
  font-weight: $fw-regular;
  text-align: center;
  @include media-min-lg {
    text-align: left;
  }
}

// Button text

.button-text {
  @extend p;
  font-weight: 300;
}

// Heading 1

h1 {
  font-family: 'Lora', serif;
  font-size: $fs-h1;
  line-height: $lh-h1;
  font-weight: $fw-regular;
  text-align: center;
  margin-top: 0;
  margin-bottom: 48px;
  @include media-min-lg {
    font-size: $fs-h1-lg;
    line-height: $lh-h1-lg;
    margin-bottom: 40px;

  }
}

// Heading 2

h2 {
  @extend h1;
  margin-bottom: 24px;
  @include media-min-lg {
    margin-bottom: 48px;
  }
}

.h2-margin-lg {
  @extend h1;
  margin-bottom: 56px;
  @include media-min-lg {
    margin-bottom: 80px;
  }
}

// Heading 3

h3 {
  font-family: 'Lora', serif;
  font-size: $fs-h3;
  line-height: $lh-h3;
  font-weight: $fw-regular;
  text-align: center;
  margin: 0 0 32px;
  @include media-min-lg {
    font-size: $fs-h3-lg;
    line-height: $lh-h3-lg;

  }
}

.typo-h3-about-us {
  @extend h3;
  font-size: $fs-h1;
  line-height: $lh-h1;
  margin: 0 0 8px;
  @include media-min-lg {
    text-align: left;
    font-size: $fs-h1-lg;
    line-height: $lh-h1-lg;
  }
}

.typo-list-about-us {
  @extend p;
  text-align: left;
  margin: 0;
  &:last-child {
    margin: 0;
  }
  > ul {
    padding: 0 0 0 26px;
    margin: 4px 0 24px 0;
    &:last-child {
      margin: 0;
    }
    list-style: none;
    > li {
      margin-bottom: 4px;
      @include media-min-lg {
        margin-bottom: 0;
      }
      &:before {
        content: '–';
        position: absolute;
        margin-left: -26px;
      }
    }
  }
  @include media-min-lg {
    margin: 32px 0 0 0;
    &:last-child {
      margin: 32px 0 0 0;
    }
  }
}

// News label
.typo-news-label {
  font-family: 'Muli', sans-serif;
  font-size: $fs-news-label;
  line-height: $lh-news-label;
  font-weight: $fw-light;
  text-align: center;
  letter-spacing: 0.6px;
  margin: 0 0 24px;
  @include media-min-lg {
    font-size: $fs-news-label-lg;
    line-height: $lh-news-label-lg;
    margin: 0 0 40px;

  }
}

// Accordion header
.typo-accordion-header {
  font-family: 'Muli', sans-serif;
  font-size: $fs-accordion-header;
  line-height: $lh-accordion-header;
  font-weight: $fw-regular;
  text-align: left;
  letter-spacing: -0.1px;
  @include media-min-lg {
    font-size: $fs-accordion-header-lg;
    line-height: $lh-accordion-header-lg;

  }
}

// Accordion label
.typo-accordion-label {
  @extend p;
  font-weight: $fw-semi-bold;
  text-align: left;
  margin: 0;
  @include m(price) {
    text-align: right;
  }
}

//Accordion content
.typo-accordion-content {
  font-family: 'Muli', sans-serif;
  font-size: $fs-accordion-content;
  line-height: $lh-accordion-content;
  font-weight: $fw-regular;
  text-align: left;
  @include media-min-lg {
    font-size: $fs-accordion-content-lg;
    line-height: $lh-accordion-content-lg;
  }
  @include m(title) {
    font-weight: $fw-semi-bold;
  }
  @include m (price) {
    text-align: left;
    @include media-min-md {
      text-align: right;
    }
  }
}

// Some According classes are missing ...

.table-data {
  @extend p;
  line-height: $lh-table-data;
}

.table-header {
  @extend .table-data;
  font-weight: $fw-semi-bold;
}

// Helper classes

.typo-align-left, .typo-align-left > p {
  text-align: left !important;
}

.typo-align-center {
  text-align: center !important;
}

.typo-align-right {
  text-align: right !important;
}

.typo-weight-light {
  font-weight: $fw-light !important;
}

.typo-weight-light {
  font-weight: $fw-light !important;
}

.typo-weight-regular {
  font-weight: $fw-regular !important;
}

.typo-weight-semi-bold {
  font-weight: $fw-semi-bold !important;
}

.typo-weight-bold {
  font-weight: $fw-bold !important;
}

.typo-margin-none, .typo-margin-none > p {
  margin: 0;
}
