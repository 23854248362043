.footer {
  align-items: start;
  background-color: $amati-light-grey;
  padding: 48px 0 48px 0;
  @include e(content) {
    margin-bottom: 48px;
    &:last-child {
      margin: 0;
    }
    @include media-min-lg {
      margin: 0;
    }
    @include m(general) {
      grid-column: 2 / 16;
      grid-row: auto;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      > div:first-child {
        margin-bottom: 24px;
        @include media-min-lg {
          margin: 0;
        }
      }
      @include media-min-xs {
        grid-column: 3 / 17;
      }
      @include media-min-md {
        grid-column: 3 / 9;
        grid-row: 2 / 3;
      }
      @include media-min-lg {
        grid-column: 2/ 7;
        grid-row: 1 / 2;
      }
      @include media-min-xl {
        grid-column: 2/ 7;
      }
    }
    @include m(contact) {
      grid-column: 2 / 16;
      grid-row: auto;
      @include media-min-xs {
        grid-column: 3 / 17;
      }
      @include media-min-md {
        grid-column: 3 / 9;
        grid-row: 1 / 2;
      }
      @include media-min-lg {
        grid-column: 8/ 11;
        grid-row: auto;
      }
      @include media-min-xl {
        grid-column: 10/ 13;
      }
    }
    @include m(opening-hours) {
      grid-column: 2 / 16;
      grid-row: auto;
      @include media-min-xs {
        grid-column: 3 / 17;
      }
      @include media-min-md {
        grid-column: 10 / 17;
        grid-row: 1 / 2;
      }
      @include media-min-lg {
        grid-column: 12/ 18;
        grid-row: auto;
      }
      @include media-min-xl {
        grid-column: 14/ 18;
      }
    }
  }
}

.f-opening-hours {
  @extend .container;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 4px;
  padding: 0;
  @include media-min-sm {
    grid-row-gap: 0;
  }

  @include e(item) {
    @include m(week-day) {
      grid-column: 1 / 2;
    }
    @include m(closed) {
      grid-column: 2 / 8;
    }

    @include m(time-am) {
      grid-column: 2 / 8;
      @include media-min-sm {
        grid-column: 2 / 5;
      }
    }

    @include m(time-pm) {
      grid-column: 2 / 8;
      @include media-min-sm {
        grid-column: 5 / 8;
      }
    }
  }
}

.footer-title {
  margin-bottom: 8px !important;
}
