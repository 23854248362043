.white-space {
  display: flex;
  height: 24px;
  @include media-min-sm {
    height: 56px;
  }
  @include media-min-xl {
    height: 104px;
  }
}

.menu {
  z-index: $zi-top;
  grid-template-rows: auto 1fr;
  align-items: flex-start;
  background-color: $amati-white;
  padding: $nav-padding 0;
  width: 100%;
  margin: 0;
  margin-bottom: calc(-1 * (#{$menu-height}));
  @include media-min-sm {
    margin-bottom: calc(-1 * (#{$menu-height-sm}));
  }
  @include media-min-lg {
    align-items: center;
    padding: $nav-padding-lg 0;
    margin-bottom: calc(-1 * (#{$menu-height-lg}));
  }
  @include media-min-xl {
    margin-bottom: calc(-1 * (#{$menu-height-xl}));
  }

  @include m(sticky) {
    position: fixed;
    top: 0;
  }

  @include m(mobile-open) {
    height: 100vh;
    overflow: scroll;
    @include media-min-lg {
      height: auto;
      overflow: auto;
    }
  }
}

.brand-mark {
  display: flex;
  grid-column-start: 2;
  grid-column-end: 10;
  @include media-min-xs {
    grid-column-start: 3;
  }
  @include media-min-lg {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  @include media-min-ul {
    grid-column-start: 4;
  }
  > a {
    display: inline-flex;
  }
  > a > img {
    height: $brand-mark-size;

    @include media-min-sm {
      height: $brand-mark-size-sm;
    }
    @include media-min-xl {
      height: $brand-mark-size-xl;
    }
  }
}

.nav-toggle {
  display: flex;
  justify-self: flex-end;
  align-self: center;
  grid-column-start: 10;
  grid-column-end: 16;
  > img {
    height: 32px;
  }
  @include media-min-xs {
    grid-column-end: 17;
  }
  @include media-min-lg {
    display: none;
  }
  @include m(closed) {
    display: none;
  }
}

nav {
  display: flex;
  grid-column-start: 2;
  grid-column-end: 17;
  @include media-min-xs {
    grid-column-start: 3;
  }
  @include media-min-lg {
    justify-self: flex-end;
    align-items: center;
    grid-column-start: 5;
  }
  @include media-min-ul {
    grid-column-end: 16;
  }
}

.nav-bar {
  display: flex;
  opacity: 1;
  transition: opacity 0.25s ease;
  flex-direction: column;
  align-items: start;
  margin: 48px 0 0 0;
  padding: 0;
  list-style: none;

  @include media-min-xs {
    margin-top: 72px
  }

  @include media-min-lg {
    margin: 0;
    display: flex;
    flex-direction: row;
  }

  @include m(mobile-closed) {
    opacity: 0;
    @include media-min-lg {
      opacity: 1;
    }
  }
  @include m(mobile-hidden) {
    display: none;
    @include media-min-lg {
      display: flex;
    }
  }

  @include e(item) {
    margin-bottom: 40px;

    @include media-min-xs {
      margin-bottom: 48px;
    }

    @include media-min-lg {
      margin-bottom: 0;
      margin-left: 48px;
    }

    @include media-min-xl {
      margin-left: 72px;
    }

    @include e(toggle) {
      display: none;
      @include media-min-lg {
        display: flex;
        align-items: center;
        > span {
          cursor: pointer;
          margin-left: 10px;
        }

        > img {
          height: 14px;
          transform: rotate(90deg);
          transition: transform 0.25s ease;
        }

        @include m(closed) {
          > img {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

}

.nav-drop-down {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $amati-white;

  @include e(item) {
    margin-bottom: 40px;

    @include media-min-xs {
      margin-bottom: 48px;
    }

    @include media-min-lg {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-min-lg {
    position: absolute;
    z-index: $zi-top;
    margin: 0 0 0 -24px;
    padding: 40px 24px 24px;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.25s ease;

    @include e(item) {

      @include m(active) {
        display: none;
      }
    }

    @include m(closed) {
      opacity: 0;
    }

    @include m(hidden) {
      display: none;
    }
  }
}

.nav-link {
  color: $amati-black;
  display: block;
  text-decoration: none;
  font-family: Lora;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;

  @include media-min-xs {
    font-size: 24px;
    line-height: 32px;
  }

  @include media-min-lg {
    font-size: 20px;
    line-height: 28px;
  }

  @include media-min-xl {
    font-size: 22px;
  }

  &:hover {
    text-decoration: underline;
  }

  @include m(active) {
    text-decoration: underline;
  }
}

.fake-content {
  grid-column-start: 2;
  grid-column-end: 18;
}
