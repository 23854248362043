/*
 * Media queries helpers
 */

 @mixin media-min-xs {
    @media (min-width: $xs-min-width) {
      @content;
    }
  
  }@mixin media-min-sm {
    @media (min-width: $sm-min-width) {
      @content;
    }
  }
  
  @mixin media-min-md {
    @media (min-width: $md-min-width) {
      @content;
    }
  }
  
  @mixin media-min-lg {
    @media (min-width: $lg-min-width) {
      @content;
    }
  }
  
  @mixin media-min-xl {
    @media (min-width: $xl-min-width) {
      @content;
    }
  }
  
  @mixin media-min-ul {
    @media (min-width: $ul-min-width) {
      @content;
    }
  }
  