/*
* Variables
*/

/*
 * Colors
 */

$amati-green: #486B5A;
$amati-white: #FFF;
$amati-light-grey: #F7F7F7;
$amati-black: #000;

/*
* Layout
*/

$number-of-columns: 16;
$number-of-columns-very-small-device: 14; // There is not enough space for 16 columns on very small devices (< 328px).

// Breakpoints
$ul-min-width: 1441px;
$xl-min-width: 1281px;
$lg-min-width: 1025px;
$md-min-width: 769px;
$sm-min-width: 489px; // Changed value from 480px to 489px to avoid horizontal scrolling.
$xs-min-width: 328px; // Changed value from 320px to 328px to avoid horizontal scrolling.

// Gutter width
$ul-gutter-width: 18px;
$xl-gutter-width: 16px;
$lg-gutter-width: 16px;
$md-gutter-width: 16px;
$sm-gutter-width: 8px;
$xs-gutter-width: 8px;

// Column width
$ul-column-width: 72px;
$xl-column-width: 64px;
$lg-column-width: 48px;
$md-column-width: 32px;
$sm-column-width: 22px;
$xs-column-width: 12px;

/*
 * Typography | fw = font weight, fs = font size, lh = line height
 */

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-semi-bold: 600;
$fw-bold: 700;

// Heading 1
$fs-h1: 28px;
$fs-h1-lg: 42px;

$lh-h1: 36px;
$lh-h1-lg: 54px;

// Heading 3
$fs-h3: 22px;
$fs-h3-lg: 24px;

$lh-h3: 28px;
$lh-h3-lg: 31px;

// Paragraph
$fs-p: 18px;
$fs-p-lg: 24px;

$lh-p: 23px;
$lh-p-lg: 30px;

// Paragraph small
$fs-p-small: 18px;

$lh-p-small: 23px;

// News label
$fs-news-label: 18px;
$fs-news-label-lg: 20px;

$lh-news-label: 23px;
$lh-news-label-lg: 25px;

// Accordion title
$fs-accordion-header: 22px;
$fs-accordion-header-lg: 28px;

$lh-accordion-header: 28px;
$lh-accordion-header-lg: 36px;

// Accordion content
$fs-accordion-content: 18px;
$lh-accordion-content: 23px;

$fs-accordion-content-lg: 20px;
$lh-accordion-content-lg: 25px;

// Table
$lh-table-data: 400px;

/*
 * Misc
 */

//z-index
$zi-top: 100;
$zi-middle: 90;

//menu
$nav-padding: 24px;
$nav-padding-lg: 40px;
$brand-mark-size: 48px;
$brand-mark-size-sm: 56px;
$brand-mark-size-xl: 64px;
$menu-height-xl: calc(#{$brand-mark-size-xl} + 2 * #{$nav-padding-lg});
$menu-height-lg: calc(#{$brand-mark-size-sm} + 2 * #{$nav-padding-lg});
$menu-height-sm: calc(#{$brand-mark-size-sm} + 2 * #{$nav-padding});
$menu-height: calc(#{$brand-mark-size} + 2 * #{$nav-padding});
