.about-us-gallery-col {
  display: flex;
  flex-direction: column;
  @include m(1) {
    grid-column: 1 / 6;
    @include media-min-md {
      grid-column: 2 / 7;
    }

    @include media-min-xl {
      grid-column: 3 / 8;
    }

    @include media-min-ul {
      grid-column: 4 / 8
    }
  }

  @include m(2) {
    grid-column: 6 / 12;
    flex-direction: column-reverse;
    > .about-us-image {
      &:first-child {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: $xs-gutter-width;
        @include media-min-md {
          margin-bottom: $md-gutter-width;
        }

        @include media-min-ul {
          margin-bottom: $ul-gutter-width;
        }
      }
    }

    @include media-min-xs {
      grid-column: 6 / 14;
    }

    @include media-min-md {
      grid-column: 7 / 13;
    }

    @include media-min-xl {
      grid-column: 8 / 12;
    }
  }

  @include m(3) {
    grid-column: 12 / 17;
    @include media-min-xs {
      grid-column: 14 / 19;
    }

    @include media-min-md {
      grid-column: 13 / 18;
    }

    @include media-min-xl {
      grid-column: 12 / 17;
    }

    @include media-min-ul {
      grid-column: 12 / 16;
    }
  }
}

.about-us-image {
  display: flex;
  width: 100%;
  height: 80px;
  background: no-repeat center;
  background-size: cover;
  margin-bottom: $xs-gutter-width;
  &:hover:after {
    content: ""; // ::before and ::after both require content
    width: 100%;
    height: 100%;
    background-color: $amati-black;
    opacity: .1;
  }
  &:last-child {
    margin-bottom: 0;
  }

  @include media-min-md {
    margin-bottom: $md-gutter-width;
    height: 184px;
  }

  @include media-min-ul {
    margin-bottom: $ul-gutter-width;
  }

  @include m(large) {
    height: 168px;
    @include media-min-md {
      height: 368px;
    }
  }
}

.employee {
  @extend .container;
  padding: 0;
  grid-column: 1 / -1;
  margin-bottom: 112px;
  &:last-child {
    margin-bottom: 0;
  }

  @include e(image) {
    grid-column: 4 / 14;
    grid-row: 1 / 2;
    margin-bottom: 56px;
    > img {
      width: 100%;
    }

    @include media-min-xs {
      grid-column: 5 / 15;
    }

    @include media-min-sm {
      grid-column: 6 / 14;
    }

    @include media-min-md {
      grid-column: 7 / 13;
    }

    @include media-min-lg {
      grid-column: 3 / 7;
      grid-row: 1 / 2;
      margin-bottom: 0;
    }

    @include media-min-ul {
      grid-column: 4 / 8;
    }
  }

  @include e(text) {
    grid-column: 2 / 16;
    grid-row: 2 / 3;
    > p {
      margin-bottom: 48px;
      &:last-child {
        margin-bottom: 0;
      }

      @include media-min-lg {
        margin-bottom: 0;
        text-align: left;
      }
    }

    @include media-min-xs {
      grid-column: 3 / 17;
    }

    @include media-min-lg {
      grid-column: 8 / 17;
      grid-row: 1 / 2;
    }
    @include media-min-ul {
      grid-column: 9 / 16;
    }
  }
}
