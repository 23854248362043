.amati-button {
  display: inline-block;
  padding: 12px 24px;
  margin: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  color: $amati-white;
  background-color: $amati-green;
  //Inherit text styles from paragraph
  @extend .button-text;
  text-decoration: none;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.04);
  &:hover {
    background-color: darken( $amati-green, 5% );
  }
}

.item-amati-button {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  @include media-min-lg {
    margin-top: 48px;
  }
}
