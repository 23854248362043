.map {
  grid-column: 1 / 17;
  grid-row: 2 / 3;
  @include media-min-xs {
    grid-column: 1 / 19;
  }

  @include media-min-md {
    grid-column: 2 / 11;
    grid-row: 1 / 2;
  }

  @include media-min-xl {
    grid-column: 3 / 11;
  }

  @include e(area) {
    width: 100%;
    height: 480px;
    @include media-min-md {
      height: 320px;
    }

    @include media-min-lg {
      height: 480px;
    }

    @include media-min-xl {
      height: 576px;
    }
  }
}

.contact-information {
  grid-column: 2 / 16;
  grid-row: 1 / 2;
  margin-bottom: 80px;
  @include media-min-xs {
    grid-column: 3 / 17;
  }
  @include media-min-md {
    margin: 0;
    grid-column: 13 / 18;
  }
}

.opening-hours {
  grid-column: 2 / 16;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  > .amati-button {
    align-self: center;
  }

  @include media-min-xs {
    grid-column: 3 / 17;
  }

  @include media-min-md {
    grid-column: 5 / 15;
  }
  @include media-min-lg {
    grid-column: 2 / 10;
  }
  @include media-min-xl {
    grid-column-start: 3;
  }
  @include e(title) {
    margin-bottom: 60px;
    @include media-min-lg {
      text-align: left;
    }
  }

  @include e(data) {
    @extend .container;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0;
    margin-bottom: 80px;
    @include media-min-sm {
      grid-template-columns: 1fr 1fr 1fr
    }

    @include e(item) {
      @include m(week-day) {
        grid-column: 1 / 7;
        margin-bottom: 8px;
        @include media-min-sm {
          grid-column: 1 / 2;
          margin-bottom: 12px;
        }
      }

      @include m(closed) {
        grid-column: 1 / 7;
        margin-bottom: 32px;
        @include media-min-sm {
          grid-column: 2 / 4;
          margin-bottom: 12px;
        }
      }

      @include m(time-am) {
        grid-column: 1 / 4;
        margin-bottom: 32px;
        @include media-min-sm {
          grid-column: 2 / 3;
          margin-bottom: 12px;
        }
      }

      @include m(time-pm) {
        grid-column: 4 / 7;
        margin-bottom: 32px;
        @include media-min-sm {
          grid-column: 3 / 4;
          margin-bottom: 12px;
        }
      }
    }
  }
}

.contact-image {
  grid-row-start: 1 / 2;
  display: none;
  @include media-min-lg {
    display: flex;
    grid-column: 11 / 18;
  }

  @include media-min-xl {
    grid-column-end: 17;
  }

  > img {
    display: flex;
    width: 100%;
  }
}
