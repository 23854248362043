/*
 * BEM helpers
 */

/// @alias element
@mixin e($element) {
    @include element($element) {
        @content;
    }
} /// @alias modifier
@mixin m($modifier) {
    @include modifier($modifier) {
        @content;
    }
} /// Block Element
/// @access public
/// @param {String} $element - Element's name
@mixin element($element) {
    &__#{$element} {
        @content;
    }
} /// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin modifier($modifier) {
    &--#{$modifier} {
        @content;
    }
}
