/*
 * Grid
 */
.container {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-column-gap: $xs-gutter-width;
  grid-template-columns: 1fr repeat($number-of-columns-very-small-device, $xs-column-width) 1fr;
  padding-top: 56px;
  padding-bottom: 56px;
  @include media-min-xs {
    grid-template-columns: 1fr repeat($number-of-columns, $xs-column-width) 1fr;
  }

  @include media-min-sm {
    grid-template-columns: 1fr repeat($number-of-columns, $sm-column-width) 1fr;
    grid-column-gap: $sm-gutter-width;
  }

  @include media-min-md {
    grid-template-columns: 1fr repeat($number-of-columns, $md-column-width) 1fr;
    grid-column-gap: $md-gutter-width;
  }

  @include media-min-lg {
    grid-template-columns: 1fr repeat($number-of-columns, $lg-column-width) 1fr;
    grid-column-gap: $lg-gutter-width;
    padding-top: 112px;
    padding-bottom: 112px;
  }

  @include media-min-xl {
    grid-template-columns: 1fr repeat($number-of-columns, $xl-column-width) 1fr;
    grid-column-gap: $xl-gutter-width;
  }

  @include media-min-ul {
    grid-template-columns: 1fr repeat($number-of-columns, $ul-column-width) 1fr;
    grid-column-gap: $ul-gutter-width;
  }

  @include m(first) {
    padding-top: calc(#{$menu-height} + 56px);
    padding-bottom: 20px;
    @include media-min-sm {
      padding-top: calc(#{$menu-height-sm} + 56px);
    }

    @include media-min-lg {
      padding-top: calc(#{$menu-height-lg} + 80px);
      padding-bottom: 48px;
    }

    @include media-min-xl {
      padding-top: calc(#{$menu-height-xl} + 80px);
    }
  }

  @include m(one-only) {
    padding-bottom: 56px;
    @include media-min-lg {
      padding-bottom: 112px;
    }
  }
}

// Items
.item-title {
  display: flex;
  justify-content: center;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column: 2 / 16;
  @include media-min-xs {
    grid-column: 3 / 17;
  }
  @include media-min-lg {
    grid-column: 1 / -1;
  }

}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column: 2 / 16;
  @include media-min-xs {
    grid-column: 3 / 17;
  }
  @include media-min-xl {
    grid-column: 5 / 15;
  }
}
