.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 2 / 16;
  @include media-min-xs {
    grid-column: 3 / 17;
  }

  @include media-min-md {
    flex-direction: row;
    justify-content: center;
  }

  @include e(image) {
    display: flex;
    width: 100%;
    height: 200px;
    background: no-repeat center/contain;
    margin-bottom: 24px;
    &:last-child {
      margin: 0;
    }
    @include media-min-md {
      margin-bottom: 0;
      width: calc(5 * #{$md-column-width} + 4 * #{$md-gutter-width});
      margin-right: $md-gutter-width;
    }

    @include media-min-lg {
      width: calc(4 * #{$xl-column-width} + 3 * #{$xl-gutter-width});
      margin-right: $lg-gutter-width;
    }

    @include media-min-ul {
      width: calc(4 * #{$ul-column-width} + 3 * #{$ul-gutter-width});
      margin-right: $ul-gutter-width;
    }
  }
}

