/*
 * Base styles
 */

body {
  margin: 0;
  padding: 0;
}

.scroll-lock {
  overflow: hidden;
  @include media-min-lg {
    overflow: scroll;
  }
}

a {
  color: $amati-black;
}
